/* global VXConfig */

import {gaHelper, gtagHelper} from './CommonUtils';
import EnumGA4Events                 from "../utils/EnumGA4Events";

function getUserStatus() {
    return VXConfig.guestIsLoggedIn ? 'li' : 'lo';
}

function getDevice() {
    if (VXConfig.device.isMobile) {
        return 'm';
    } else if (VXConfig.device.isTablet) {
        return 't';
    } else {
        return 'd';
    }
}

function trackClick(event = '', label = '', category = VXConfig.routeName, withUserStatus = true) {
    const eventString    = 'click' + ((category === 'Startpage') ? '_sp_' : '_') + event;
    const categoryString =  category + '_' + getDevice() + (withUserStatus ? '_' + getUserStatus() : '');
    gaHelper('send', 'event', categoryString, eventString, label);
	gtagHelper(EnumGA4Events.DEFAULT_PARAMETER_EVENT, EnumGA4Events.CUSTOM_EVENT_TRACK_CLICK, {
		[EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_CATEGORY]: category,
		[EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_EVENT]: event,
		[EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_LABEL]: label,
		[EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_USER_STATUS]: getUserStatus(),
	});
}

function trackNavbarClick(event = '', label = '') {
    trackClick('nb_' + event, label, 'navbar');
}

function trackNavbarIconClick(event = '', label = '') {
    trackClick('nbi_' + event, label, 'navbaricon');
}

function trackUserMenuClick(event = '', label = '') {
    trackClick('um_' + event, label, 'usermenu');
}

function trackCategoriesClick(event = '', label = '') {
    trackClick('cat_' + event, label, 'categories', false);
}

function trackFooterClick(event = '', label = '', withUserStatus = false) {
    trackClick('footer_' + event, label, 'footer', withUserStatus);
}

function trackLivechatButtonClick(event = '', label = '') {
    gaHelper('send', 'event', "CTA Clicks", "Click_" + event, label);
	gtagHelper(EnumGA4Events.DEFAULT_PARAMETER_EVENT, EnumGA4Events.CUSTOM_EVENT_TRACK_CLICK, {
		[EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_CATEGORY]: 'cta_click',
		[EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_EVENT]: event,
		[EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_LABEL]: label,
		[EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_USER_STATUS]: getUserStatus(),
	});
}

function trackCTAClick(event = '', label = '') {
    trackLivechatButtonClick(event, label);
}

function trackMixedFeed(event = '', label = '') {
    gaHelper('send', 'event', "CTA Clicks", "mixed_" + event, label);
	gtagHelper(EnumGA4Events.DEFAULT_PARAMETER_EVENT, EnumGA4Events.CUSTOM_EVENT_TRACK_CLICK, {
		[EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_CATEGORY]: 'cta_mixed_feed',
		[EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_EVENT]: event,
		[EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_LABEL]: label,
		[EnumGA4Events.CUSTOM_PARAMETER_TRACK_CLICK_USER_STATUS]: getUserStatus(),
	});
}

export {
    trackClick,
    trackNavbarClick,
    trackUserMenuClick,
    trackNavbarIconClick,
    trackCategoriesClick,
    trackFooterClick,
    trackLivechatButtonClick,
    trackCTAClick,
    trackMixedFeed
};